import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {useTheme} from '@mui/material';


export default function PolicyView() {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h1" color="primary" gutterBottom>
        Verantwoording & Beleid
      </Typography>
      <Typography variant="body1" component="p">
        Versie 2.0 d.d. 22 april 2024
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Stichting Open Politiek
      </Typography>
      <Typography variant="body1" component="p">
        OpenVerkiezingen.nl is een initiatief van Stichting Open Politiek.
        Stichting Open Politiek heeft als missie de Nederlandse democratie toegankelijker en
        transparanter te maken en misinformatie te voorkomen door de inzet van kunstmatige
        ntelligentie en data. Stichting Open Politiek is een volledig onafhankelijk initiatief waar
        geen politieke partij, bedrijf of ander soort organisatie achter zit.
        Het beleid van openverkiezingen.nl is opgesteld en vastgesteld door het
        bestuur van Stichting Open Politiek.
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Politieke partijen
      </Typography>
      <Typography variant="h2" color="secondary" gutterBottom>
        Deelname politieke partijen - Europese verkiezingen
      </Typography>
      <Typography variant="body1" component="p">
        We nemen programma&apos;s mee van politieke partijen die bij de Kiesraad zich hebben
        geregistreerd als politieke partij en op de dag van kandidaatstelling hun kandidatenlijsten
        inleveren voor de eerstvolgende Europese verkiezing en die op dit moment één of meer zetels
        hebben in het Nederlandse parlement. Op 24 april 2024 gaat openverkiezingen.nl live voor de
        Europese verkiezingen van 2024 met de tot dan toe beschikbare definitieve programma&apos;s.
        Indien een partij zijn definitieve programma publiceert ná 24 april wordt dit programma
        binnen 3 dagen nadat deze definitief is toegevoegd aan openverkiezingen.nl
      </Typography>
      <Typography variant="body1" component="p">
        Op 31 oktober 2023 gaat openverkiezingen.nl live met de tot dan toe beschikbare definitieve
        programma’s. Indien een partij op 22 oktober twee zetels of meer peilt maar geen definitief
        programma beschikbaar heeft gesteld voor 1 november, wordt dit programma binnen 3 dagen
        nadat deze definitief is, toegevoegd aan openverkiezingen.nl.
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Algoritme en data verwerking
      </Typography>
      <Typography variant="h2" color="secondary" gutterBottom>
        Werking OpenVerkiezingen.nl
      </Typography>
      <Typography variant="body1" component="p">
        Op de pagina <Link
          to="/content/werking"
          style={{color: theme.palette.secondary.main}}>werking</Link> wordt
        uitgelegd hoe de technische implementatie van openverkiezingen.nl werkt.
      </Typography>
      <Typography variant="body1" component="p">
        Om een overzichtelijke en doorzoekbare vergelijking te maken, hebben we de ruim 1300
        pagina&apos;s uit de vormgeving van de partijen gehaald en de platte tekst omgezet naar een
        HTML-structuur. Daarbij hebben we per partijprogramma de volgende elementen geïdentificeerd:

        <ul>
          <li>Titel van het verkiezingsprogramma</li>
          <li>Ondertitel van het verkiezingsprogramma</li>
          <li>Voorwoord</li>
          <li>Hoofdstuktitel</li>
          <li>Introductie op een hoofdstuk</li>
          <li>Tussenkop</li>
          <li>Kleinere tussenkop</li>
          <li>Lopende tekst</li>
          <li>Opsomming</li>
          <li>Kader</li>
        </ul>

        Alle andere elementen hebben we niet uit het partijprogramma overgenomen.
        Dat zijn bijvoorbeeld afbeeldingen, bijschriften, verantwoordingen, colofons, uitgelichte
        quotes en losse motto’s. Ook bijlagen worden niet overgenomen. Lopende tekst hebben we
        zonder stijlelementen (zoals dikgedrukte of cursieve woorden) overgenomen. Aan de volgorde
        en inhoud hebben we geen aanpassingen gedaan. Alleen financiële paragrafen met
        doorrekeningen nemen we niet mee. Deze geven immers niet aan wat een partij wil,
        maar hoe ze het bekostigen. We gebruiken de definitieve partijprogramma´s na vaststelling
        in ledenraden en congressen. Tijdens dit soort evenementen hebben leden van de partij de
        mogelijkheid om aanpassingen aan het programma te doen. Om zo zorgvuldig en compleet te zijn
        in wat een partij vindt, nemen wij daarom alleen definitieve programma&apos;s mee die
        officieel zijn vastgesteld conform de bepalingen van de reglementen van de betreffende
        partij.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Verwerking van gebruikersgegevens
      </Typography>
      <Typography variant="body1" component="p">
        In ons <Link
          to="/content/werking"
          style={{color: theme.palette.secondary.main}}>privacy statement</Link> vind je hoe we met
        jouw gegevens om gaan.
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Fouten, onzekerheid & validatie
      </Typography>
      <Typography variant="h2" color="secondary" gutterBottom>
        Zekerheid van de resultaten
      </Typography>
      <Typography variant="body1" component="p">
        Omdat wij gebruik maken van kunstmatige intelligentie bestaat het risico dat een foutief
        antwoord getoond wordt. Dit is inherent aan deze technologie en wij bieden daarom geen
        zekerheidsgarantie.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Communicatie over onzekerheid
      </Typography>
      <Typography variant="body1" component="p">
        Uit principe communiceren wij bij ieder antwoord dat dit gegenereerd is met kunstmatige
        intelligentie en daardoor fouten kan bevatten. Indien u antwoorden van de site citeert of
        anderszins hergebruikt zonder verdere validatie van het antwoord, vragen wij je deze
        waarschuwing over te nemen.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Validatie van de antwoorden
      </Typography>
      <Typography variant="body1" component="p">
        Omdat sommige stellingen vaker terugkomen loont het om voor deze stellingen het antwoord te
        valideren. Dit doen we door op basis van gebruik, steekproefsgewijs de antwoorden te
        checken. Waar dit gedaan is wordt de waarschuwing onder het resultaat vervangen door
        “deze stelling is handmatig gecontroleerd”.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Correctie van fouten
      </Typography>
      <Typography variant="body1" component="p">
        Fouten zullen voorkomen in de antwoorden van openverkiezingen.nl. Wij wijzigen antwoorden in
        principe nooit. Dit doen wij bewust omdat het onmogelijk is vooraf een duidelijke
        scheidslijn te kiezen tussen een “nuanceverschil” en een fout. Wat door een partij zelf als
        fout kan worden aangemerkt kan door een andere partij worden aangemerkt als spin. Er zijn
        echter een aantal uitzonderingsgronden op deze positie:
        <ul>
          <li>Het antwoord is van de strekking dat een partij geen standpunt inneemt,
            maar het blijkt dat de verkeerde fragmenten uit het verkiezingsprogramma zijn gebruikt.
            In dat geval zullen wij het antwoord herberekenen met de juiste fragmenten uit het
            verkiezingsprogramma.</li>
          <li>Er staat een feitelijke tegenstelling in de geciteerde fragmenten en het gegenereerde
            antwoord. In dit geval zullen wij het antwoord herberekenen en controleren dat de
            feitelijke tegenstelling verdwenen is.</li>
        </ul>
        Bij alle herberekende resultaten zal dit worden weergegeven.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Verzoeken tot wijziging
      </Typography>
      <Typography variant="body1" component="p">
        Op verzoeken tot wijziging van de resultaten wordt niet ingegaan.
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Gebruik website
      </Typography>
      <Typography variant="h2" color="secondary" gutterBottom>
        Thema en stellingen
      </Typography>
      <Typography variant="body1" component="p">
        Gebruikers kunnen zelf een stelling invullen.
        Om de site zo gebruiksvriendelijk te maken kunnen gebruikers ook een vooraf gegenereerde
        stelling kiezen die de gebruiker kan aanklikken. Deze vooraf geformuleerde stellingen zijn
        bepaald door te onderzoeken welke vraagstukken veelal behandeld worden in
        verkiezingsprogramma&apos;s en in de media. Daarnaast maken we een onderscheid tussen
        positieve en negatieve frames bij het formuleren van de stellingen.
        Indien een bepaalde stelling veel wordt ingevuld op de site of in de media wordt gebruikt
        kan deze aan de site toegevoegd worden.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Vergelijking tussen partijen
      </Typography>
      <Typography variant="body1" component="p">
        Het algoritme maakt zelf geen vergelijking tussen partijen. Wel kan de gebruiker meerdere
        partijen aanklikken waarna de resultaten van elke partij naast elkaar gepresenteerd worden.
        Dit maakt het voor de gebruiker gemakkelijker om zelf een vergelijking te trekken tussen de
        resultaten van de opgegeven stelling.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Citaat en bronvermelding
      </Typography>
      <Typography variant="body1" component="p">
        Het citeren van de informatie van de site openverkiezingen.nl is toegestaan onder
        bronvermelding.
      </Typography>

      <Typography variant="h1" color="primary" gutterBottom>
        Samenwerking
      </Typography>
      <Typography variant="body1" component="p">
        Stichting Open Politiek is voor de Europese verkiezingen 2024 een samenwerking
        aangegaan met BNR Nieuwsradio voor het initiatief openverkiezingen.nl. Deze samenwerking
        houdt in dat BNR Nieuwsradio de operationele kosten van openverkiezingen.nl dekt, zij ruimte
        beschikbaar stelt op de landingspagina van de website voor een widget naar
        openverkiezingen.nl en redactionele en promotionele aandacht besteedt aan de website.
        Hierbij is BNR Nieuwsradio volledig onafhankelijk en heeft Stichting Open Politiek geen
        invloed op de inhoud van berichtgeving. BNR Nieuwsradio heeft vice versa geen invloed op de
        inhoud van openverkiezingen.nl Binnen deze samenwerking is de site
        openverkiezingen.nl en het functioneren daarvan volledig de verantwoordelijkheid
        van Stichting Open Politiek.
      </Typography>


    </>
  );
}
