import {Alert, IconButton} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import * as React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import {AccountabilityDialog} from './dialogs';

export default function UsingAIWarning({
  party,
  paragraphs,
  validated,
  sx,
}) {
  return (
    <Box sx={sx}>
      {validated &&
        <Alert
          color="success"
          icon={<VerifiedIcon/>}
          action={<AccountabilityDialog
            checked={false}
            openElement={
              <IconButton color="success"><InfoIcon/></IconButton>
            }
            party={party}
            paragraphs={paragraphs}/>}>
          <b>Deze AI-samenvatting is handmatig gechecked</b>
        </Alert>
      }
      {!validated &&
        <Alert
          color="warning"
          sx={{mt: 3}}
          icon={<WarningAmberIcon/>}
          action={<AccountabilityDialog
            checked={false}
            openElement={
              <IconButton color="warning"><HelpIcon/></IconButton>
            }
            party={party}
            paragraphs={paragraphs}/>}>
          <b>Pas op, deze AI-samenvatting kan fouten bevatten!</b>
        </Alert>
      }
    </Box>
  );
}
