import Box from '@mui/material/Box';
import {Chip} from '@mui/material';
import * as React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {useFetchPostOrPut} from '../hooks';
import {SUMMARIZER_URI} from '../globals';
import {useEffect} from 'react';
import PendingHandler from './PendingHandler';
import Typography from '@mui/material/Typography';

export default function Suggestions({
  onSelect,
  query,
  sx,
}) {
  const {data, send, clear, isPending} = useFetchPostOrPut(null);

  useEffect(() => {
    clear();
    if (query) {
      send(`${SUMMARIZER_URI}/suggest`, {'statement': query});
    }
  }, [query]);

  return <Box
    sx={{
      ...{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
      }, ...sx,
    }}>
    <PendingHandler
      pending={isPending}>
      We bedenken nu gerelateerde stellingen.
    </PendingHandler>
    {data?.suggestions?.map((statement, i) => (
      <Chip
        key={i}
        label={statement}
        color="secondary"
        variant="outlined"
        sx={{
          'height': 'auto',
          'width': '100%',
          'pt': '2px',
          'pb': '2px',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }}
        onClick={() => {
          amplitude.track('Suggestion clicked');
          onSelect(statement);
        }}
      />
    ))}
    {!data?.suggestions?.length > 0 && !isPending &&
      <Typography>
        We konden geen gerelateerde stellingen vinden.
      </Typography>
    }
  </Box>;
}
