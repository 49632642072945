import Box from '@mui/material/Box';
import {Chip} from '@mui/material';
import * as React from 'react';
import {useFetchGet} from '../hooks';
import {BASE_URI} from '../globals';

export default function Demos({
  onSelect,
  disabled,
  sx,
}) {
  const {data: demos} = useFetchGet(`${BASE_URI}/demo/`, []);
  return <Box
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    display="flex">
    <Box
      sx={{...{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }, ...sx}}>
      {demos?.map((demo, i) => (
        <Chip
          key={i}
          label={demo.statement.statement}
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => {
            onSelect(demo);
          }}
        />
      ))}
    </Box>
  </Box>;
}
