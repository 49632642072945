export const shareSummary = (statement, summary, party, location) => {
  navigator.share({
    title: `Wist jij dit van ${party.party_identifier}?`,
    text: `Wist jij dat ${party.party_identifier} dit ` +
      `vindt van de stelling "${statement}":

${summary}`,
    url: 'https://openverkiezingen.nl',
  });
};
