import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


export default function PrivacyView() {
  return (
    <>
      <Typography variant="h1" color="primary" gutterBottom>
        Privacy (versie 1.1)
      </Typography>
      <Typography variant="body1" component="p">
        We nemen uw privacy zeer serieus.
        We verwerken uw informatie op een veilige manier en zoveel als mogelijk geanonimiseerd.
        Persoonsgegevens worden in overeenstemming met wet- en regelgeving behandeld. Op deze pagina
        leggen wij u in onze privacy en cookiestatement uit op welke manier wij met uw gegevens
        omgaan.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Wie zit er achter OpenVerkiezingen.nl?
      </Typography>
      <Typography variant="body1" component="p">
        OpenVerkiezingen.nl is een initiatief van Stichting Open Politiek. Deze stichting heeft als
        doel het versterken van de Nederlandse democratie door de inzet van data & kunstmatige
        intelligentie.

        Stichting Open Politiek is ingegeschreven bij de Kamer van Koophandel onder nummer 91631270
        en te bereiken via email op <Link color="secondary" href="mailto:info@openverkiezingen.nl">
          info@openverkiezingen.nl
        </Link>.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Welke gegevens verwerkt OpenVerkiezingen.nl om te kunnen functioneren?
      </Typography>
      <Typography variant="body1" component="p">
        Om OpenVerkiezingen te laten werken slaan wij een aantal technische gegevens van u op.
        Concreet gaat het bijvoorbeeld om zaken als:
        <ul>
          <li>Het feit dat u onze website opent</li>
          <li>De API&apos;s die u aanroept</li>
          <li>Gegevens over uw browser en uw besturingssysteem</li>
          <li>Gegevens over het apparaat dat u gebruikt</li>
        </ul>
      </Typography>
      <Typography variant="body1" component="p">
        Wanneer u een of meerdere partij(en) selecteert en OpenVerkiezingen.nl vraagt hun
        standpunten samen te vatten wordt deze vraag (de geselecteerde partijen en de gevraagde
        stelling) naar onze server verzonden. Het is dus niet
        zo dat alle verwerking van data lokaal gebeurd. Dit is helaas technisch noodzakelijk omdat
        wij afhankelijk zijn van technologie die momenteel nog niet (efficient) lokaal te draaien
        is.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Welke gegevens worden opgeslagen als ik een stelling uitvraag?
      </Typography>
      <Typography variant="body1" component="p">
        Als u een stelling invoert op OpenVerkiezingen.nl wordt uw vraag inclusief de geselecteerde
        partijen naar de server verzonden. OpenVerkiezingen.nl slaat op dat deze vraag gesteld is,
        maar doet dit zonder gegevens op te slaan die het mogelijk maken deze vraag naar u te
        herleiden.
      </Typography>
      <Typography variant="body1" component="p">
        Wij verzamelen deze gegevens met twee doelen:
        <ol>
          <li>Om zo te kunnen bepalen welke stellingen vaak gezocht worden en deze handmatig te
            kunnen valideren. Zo verhogen we de betrouwbaarheid van de site.</li>
          <li>Om analyses te kunnen doen welke thema&apos;s leven onder alle gebruikers van
            de site.</li>
        </ol>
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Welke gegevens worden opgeslagen als ik het WhatsApp nummer gebruik??
      </Typography>
      <Typography variant="body1" component="p">
        Voor het gebruik van het WhatsApp nummer is het helaas nodig dat wij jouw telefoonnummer
        opslaan. Dit omdat onze software in staat moet zijn jou een bericht terug te sturen en de
        gesprekshistorie bij te houden. Hierom is het gebruik van ons WhatsApp nummer niet volledig
        anoniem. Wij bewaren berichten voor maximaal 1 jaar.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Welke analytische gegevens verzamelt OpenVerkiezingen.nl over mij?
      </Typography>
      <Typography variant="body1" component="p">
        OpenVerkiezingen.nl tracked gebruik met behulp van Amplitude, dit is een soort Google
        Analytics. We hebben ons best gedaan Amplitude zo privacy vriendelijk mogelijk in te stellen
        zo hebben wij de volgende maatregelen genomen:
        <ul>
          <li>Wij gebruiken geen cookies of andere stukjes data die persistent zijn over
            browsersessies op het apparaat van de gebruiker waarmee we een gebruiker over meerdere
            bezoeken zouden kunnen identifieren.</li>
          <li>Wij slaan geen gegevens op zoals IP-adressen</li>
          <li>Wij slaan alle gegevens op servers binnen de EU op.</li>
        </ul>
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Hoe lang bewaart OpenVerkiezingen.nl mijn gegevens?
      </Typography>
      <Typography variant="body1" component="p">
        De stellingen ingevoerd in OpenVerkiezingen.nl bewaren wij voor onbepaalde tijd.
        Loggegevens en andere technisch functionele informatie wordt maximaal 30 dagen bewaard.
        Analytische data die niet terug te leiden is tot een natuurlijk persoon bewaren wij voor
        maximaal 3 jaar. De gestelde vragen zoals beschreven
        onder <b>&quot;Welke gegevens worden opgeslagen als ik een
          stelling uitvraag?&quot;</b> bewaren wij voor onbepaalde tijd.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Gebruikt OpenVerkiezingen.nl cookies?
      </Typography>
      <Typography variant="body1" component="p">
        OpenVerkiezingen.nl gebruikt geen andere dan de functioneel noodzakelijke cookies
        zoals sessie cookies. Alle cookies vervallen zodra jij jouw browser afsluiten.
        Ook slaat OpenVerkiezingen.nl geen informatie op op andere plekken zoals e.g.
        jouw browser&apos;s LocalStorage.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Kan ik cookies verwijderen of uitschakelen?
      </Typography>
      <Typography variant="body1" component="p">
        Cookies verwijderen kan altijd via jouw browser. Uitschakelen is niet mogelijk
        omdat OpenVerkiezingen.nl alleen gebruik maakt van cookies die functioneel noodzakelijk
        zijn.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Kan dit privacy- en cookiestatement worden gewijzigd?
      </Typography>
      <Typography variant="body1" component="p">
        Het bestuur van Stichting Open Politiek kan dit statement wijzigen. In dat geval wordt
        het versienummer boven aan deze pagina bijgewerkt.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Ik heb een vraag, waar kan ik die stellen?
      </Typography>
      <Typography variant="body1" component="p">
        Voor vragen kunt u contact opnemen
        via <Link color="secondary" href="mailto:info@openverkiezingen.nl">
          info@openverkiezingen.nl
        </Link>
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Hoe dien ik een klacht in?
      </Typography>
      <Typography variant="body1" component="p">
        Natuurlijk helpen wij u graag als u een klacht heeft, neemt in u dat geval graag contact op
        via <Link color="secondary" href="mailto:info@openverkiezingen.nl">
          info@openverkiezingen.nl
        </Link>. U heeft ook het recht om een klacht in te dienen bij de Autoriteit
        Persoonsgegevens.
      </Typography>
    </>
  );
}
