import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Slide, useScrollTrigger} from '@mui/material';
import {Link} from 'react-router-dom';
import {DonationDialog} from './dialogs';


const navItems = [
  {
    path: '/',
    text: 'Wat zeggen de partijen?',
  },
  {
    path: '/content/werking',
    text: 'Hoe werkt het?',
  },
  {
    path: '/content/beleid',
    text: 'Verantwoording & beleid',
  },
  {
    path: '/content/over',
    text: 'Over ons & Contact',
  },
  {
    path: '/content/privacy',
    text: 'Privacy',
  },
];

function HideOnScroll({children}) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function MenuBar({
  window,
  logoOnFullScreen,
  sx,
}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
      <Typography variant="h6" sx={{my: 2}}>
        OpenVerkiezingen.nl
      </Typography>
      <Divider/>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.path}
            disablePadding
            component={Link}
            to={item.path}>
            <ListItemButton sx={{textAlign: 'center'}}>
              <ListItemText primary={item.text} sx={{color: (t) => t.palette.text.primary}}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <DonationDialog
        sx={{display: 'inline'}}
        openElement={
          <Button
            color="secondary"
            variant="contained">Doneer</Button>
        }></DonationDialog>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <HideOnScroll>
        <AppBar
          component="nav"
          sx={{...{boxShadow: 'none'}, ...sx}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {md: 'none'}}}
            >
              <MenuIcon/>
            </IconButton>
            {logoOnFullScreen && <Box
              sx={{display: {xs: 'none', md: 'block'}, mt: '12px', minWidth: '250px'}}>
              <img src="/logo_white.svg" />
            </Box>}
            <Box sx={{flexGrow: 1}}></Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, mt: '10px', minWidth: '250px'}}>
              <img src="/logo_white.svg" />
            </Box>
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
              {navItems.map((item) => (
                <Link to={item.path} key={item.path} >
                  <Button sx={{
                    color: (t) => t.palette.primary.light,
                    textTransform: 'none',
                    fontSize: '15px',
                    mr: '15px',
                  }}>
                    {item.text}
                  </Button>
                </Link>
              ))}
              <DonationDialog
                sx={{display: 'inline'}}
                openElement={
                  <Button
                    color="secondary"
                    variant="contained">Doneer</Button>
                }></DonationDialog>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            'display': {xs: 'block', md: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: '300px'},
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>

  );
}

export default MenuBar;
