import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Pending({children, sx}) {
  return <Box
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    sx={{
      flexDirection: 'column',
      display: 'flex',
      ...sx,
    }}>
    <CircularProgress color="secondary" sx={{m: 1}}/>
    <Typography
      component="span"
      color="text.secondary">
      {children}
    </Typography>
  </Box>;
}

export default function PendingHandler({pending, children, sx}) {
  return <>{pending && <Pending sx={sx}>{children}</Pending>}</>;
}
