import Typography from '@mui/material/Typography';
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ControlledAccordion from './ControlledAccordion';
import SimplifiedSummaryDialog from './SimplifiedSummaryDialog';
import SummaryActions from './SummaryActions';

export default function DefaultSummary({
  data,
  setData,
  simplificationDialogOpen,
  setSimplificationDialogOpen,
  summary,
  generating,
  party,
  election,
  setElection,
  handleExpansion,
  expanded,
}) {
  return <>
    <SimplifiedSummaryDialog
      statementResult={data}
      setStatementResult={setData}
      open={simplificationDialogOpen}
      onClose={() => setSimplificationDialogOpen(false)}>
    </SimplifiedSummaryDialog>

    <Typography
      color="text.primary"
      variant="p"
      gutterBottom>
      {summary}
      {generating && <CircularProgress
        color="secondary"
        fontSize="6px"
        size="12px"
        sx={{marginLeft: '10px'}}/>}
    </Typography>
    <SummaryActions
      data={data}
      sx={{
        mt: 1,
        mb: 1,
      }}
      onSimplify={() => setSimplificationDialogOpen(true)}
      party={party}
      election={election || data?.election?.electionIdentifier}
      setElection={setElection}/>
    <ControlledAccordion
      paragraphs={data?.paragraphs}
      expanded={expanded}
      handleExpansion={handleExpansion}
    ></ControlledAccordion>
  </>;
}
