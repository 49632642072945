import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import * as React from 'react';
import _ from 'lodash';

import PartyButton from './PartyButton';

export default function PartyButtons({
  parties,
  isProcessing,
  onClick,
  currentParties,
  highlighted,
  maxSelectedParties = 3,
  sx,
}) {
  const partyLimitReached = currentParties?.length == maxSelectedParties;

  const getToolTipForParty = (party, isSelected) => {
    if (!party.active) {
      return 'Voor deze partij hebben we helaas nog geen programma toegevoegd';
    } else if (partyLimitReached && !isSelected) {
      return `Je kan maximaal ${maxSelectedParties} partijen vergelijken`;
    } else {
      return party.name;
    }
  };

  return (
    <Box
      sx={{...{
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }, ...sx}}>
      <Grid
        container
        spacing={2}
        columns={{xs: 4, sm: 8, md: 15}}
        justifyContent="center"
        alignItems="center">
        {parties?.map((party) => {
          const isSelected = (
            _.includes(currentParties.map((p) => p.party_identifier), party.party_identifier)
          );

          const isDisabled = (
            (isProcessing || !party.active || partyLimitReached) && !isSelected
          );

          return (
            <Grid item xs={1} key={party.party_identifier}>
              <PartyButton
                disabled={isDisabled}
                notAvailable={!party.active}
                tooltip={getToolTipForParty(party, isSelected)}
                onClick={() => onClick(party)}
                image={`/partyLogos/${party.party_identifier}.svg`}
                selected={isSelected}/>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
