import * as React from 'react';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import {Fade} from '@mui/material';
import Button from '@mui/material/Button';
import SuggestStatementDialog from './SuggestStatementDialog';
import {useState} from 'react';
import * as amplitude from '@amplitude/analytics-browser';


export default function StatementInput({
  value,
  onChange,
  disabled,
  commitStatement,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SuggestStatementDialog
        open={open}
        onClose={() => setOpen(false)}
        commitStatement={commitStatement}></SuggestStatementDialog>
      <TextField
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        sx={{
          'maxWidth': '900px',
          '& .MuiFilledInput-root': {
            backgroundColor: '#FFFFFF',
            flexWrap: {
              xs: 'wrap',
              sm: 'nowrap',
            },
          },
          '& .MuiFilledInput-root:hover': {
            backgroundColor: '#FFFFFF',
          },
          '& .MuiFilledInput-focused': {
            backgroundColors: '#FFFFFF',
          },
          '& .MuiFilledInput-focused:hover': {
            backgroundColors: '#FFFFFF',
          },
        }}
        id="standard-basic"
        label="Typ hier jouw stelling of gebruik de hulp..."
        value={value}
        multiline
        maxRows={4}
        variant="filled"
        color="primary"
        InputProps={{
          endAdornment: (<React.Fragment>
            <Fade in={!value} unmountOnExit>
              <Button
                sx={{
                  width: {
                    sm: '180px',
                    xs: '100%',
                  },
                  mt: {
                    xs: '10px',
                    sm: '-18px',
                  },
                }}
                onClick={() => {
                  setOpen(true);
                  amplitude.track('Open statement helper');
                }}
                variant="outlined"
                endIcon={<HelpIcon/>}>Stellinghulp</Button>
            </Fade>
          </React.Fragment>),
        }}
      />
    </>

  );
}
