import Box from '@mui/material/Box';
import 'react-bubble-ui/dist/index.css';
import BubbleUI from 'react-bubble-ui';
import * as React from 'react';

import PartyButton from './PartyButton';
import {Link} from 'react-router-dom';
import {useMediaQuery, useTheme} from '@mui/material';
import {useMemo, useState} from 'react';

export default function WidgetPartyButtons({
  parties,
  disabled,
  computeLink,
  linkProps,
  sx,
}) {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [hasInteracted, setHasInteracted] = useState(false);
  const showSwipeHint = !hasInteracted && !mediumScreen;

  const shuffledParties = useMemo(() => _.shuffle(parties), [parties]);

  const options = {
    size: 74,
    minSize: 40,
    gutter: 0,
    provideProps: true,
    numCols: 8,
    yRadius: 80,
    xRadius: mediumScreen ? 270 : 66,
    cornerRadius: 15,
    showGuides: false,
    compact: true,
    gravitation: 8,
  };

  const getToolTipForParty = (party) => {
    if (disabled) {
      return 'Voer een stelling in om een party te kunnen selecteren.';
    }
    return null;
  };

  return (
    <Box
      sx={{
        ...{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }, ...sx,
      }}
      onTouchStart={() => setHasInteracted(true)}

    >
      {showSwipeHint && <img
        src="/bnrElements/swipeLeft.gif"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100px',
          opacity: 0.45,
          transform: 'translate(-50%, -50%)',
          zIndex: 100,
        }} />}
      <BubbleUI
        options={options}
        style={{height: '100%', width: '100%'}}
      >
        {shuffledParties?.map((party, index) => {
          const button = <PartyButton
            key={party.party_identifier}
            disabled={disabled || !party.active}
            notAvailable={!party.active}
            tooltip={getToolTipForParty(party)}
            sx={{
              backgroundColor: 'none',
              animationDelay: `${Math.round(index * 500)}ms`,
            }}
            image={`partyLogos/${party.party_identifier}.svg`}/>;

          if (computeLink && !disabled) {
            const linkHref = computeLink(party);
            return (
              <Link
                key={party.party_identifier}
                to={linkHref}
                {...linkProps}>
                {button}
              </Link>
            );
          }

          return button;
        })}
      </BubbleUI>
    </Box>
  );
}
