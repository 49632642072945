import Box from '@mui/material/Box';
import * as React from 'react';
import StatementInput from './StatementInput';
import {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {MIN_STATEMENT_LENGTH} from '../globals';

export default function StatementEntry({
  statement,
  onStatementChanged,
  disabled,
  sx,
}) {
  const [innerStatement, setInnerStatement] = useState(statement);

  useEffect(() => {
    setInnerStatement(statement);
  }, [statement]);

  const commitStatement = (value) => {
    if (!value) {
      onStatementChanged(innerStatement);
    } else {
      setInnerStatement(value);
      onStatementChanged(value);
    }
  };

  return <Box
    sx={sx}
    textAlign="center"
    justifyContent="center"
    alignItems="center">
    <Box
      mb={2}
      textAlign="center"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      }}>
      <StatementInput
        disabled={disabled}
        value={innerStatement}
        onChange={setInnerStatement}
        commitStatement={commitStatement}/>
      <Button
        color="secondary"
        variant="contained"
        disabled={disabled || innerStatement?.length < MIN_STATEMENT_LENGTH}
        onClick={() => commitStatement()}
      >Wat zeggen deze partijen?</Button>
    </Box>
  </Box>;
}
