import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {Card, CardContent, CardActions, Grid, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {DONATION_LINK} from '../globals';

function HexagonAvatar({imgSrc, sx}) {
  return <Box sx={{
    ...{
      clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
      p: '5px',
      backgroundColor: (t) => t.palette.secondary.main,
    },
    ...sx,
  }}>
    <Box sx={{
      ...{
        clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      },
    }}>
      <img src={imgSrc} style={{minWidth: '100%', minHeight: '100%', objectFit: 'cover'}} />
    </Box>
  </Box>;
}

function TeamCard({
  name,
  title,
  imgSrc,
  email,
  children,
}) {
  return <>
    <Card variant="outlined" sx={{
      overflow: 'visible',
      marginTop: '63px',
      paddingTop: '35px',
      height: 'calc(100% - 63px)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <HexagonAvatar
        imgSrc={imgSrc}
        sx={{
          height: '125px',
          width: '125px',
          position: 'absolute',
          top: '0',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }} />
      <CardContent sx={{flexGrow: 1}}>
        <Typography variant="h2" color="primary">
          {name}
        </Typography>
        <Typography variant="subtitle1" color="secondary" gutterBottom>
          {title}
        </Typography>
        {children && <Typography variant="body1" component="p">
          {children}
        </Typography>}
      </CardContent>
      {email && <CardActions disableSpacing>
        <IconButton component={Link} href={`mailto:${email}`} color="primary">
          <AlternateEmailIcon />
        </IconButton>
      </CardActions>}
    </Card>
  </>;
}

export default function AboutAndContactView() {
  return (
    <>
      <Typography variant="h1" color="primary" gutterBottom>
        Contact
      </Typography>
      <Typography variant="body1" component="p">
        OpenVerkiezingen.nl is een initiatief van Stichting Open Politiek. Stichting Open Politiek
        heeft als missie de Nederlandse democratie toegankelijker en transparanter te maken
        en misinformatie te voorkomen door de inzet van kunstmatige intelligentie en data.
      </Typography>
      <Typography variant="body1" component="p">
        Voor vragen verwijzen we u graag naar ons
        emailadres: <Link color="secondary" href="mailto:info@openverkiezingen.nl">
          info@openverkiezingen.nl
        </Link>.
      </Typography>
      <Typography variant="body1" component="p">
        Stichting Open Politiek is onafhankelijk, zonder enige politieke kleur. De ontwikkeling van
        OpenVerkiezingen.nl wordt volledig gefinancierd uit giften en donaties. Draagt u onze missie
        ook een warm hart toe en wilt u doneren. Dan kan dat op:
      </Typography>
      <Box
        sx={{
          // backgroundColor: (t) => t.palette.primary.light,
          display: 'inline-block',
          p: 0,
          pt: 4,
        }}>
        <img
          src="/partnerLogos/stichtingOpenPolitiek.svg"
          style={{
            height: '100px',
            float: 'left',
            marginRight: '15px',
            marginBottom: '15px',
          }}/>
        <Typography variant="body1" component="p" color="primary">
          NL27 BUNQ 2099 1852 12<br />
          t.n.v. Stichting Open Politiek
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          component={Link}
          target="_blank"
          href={DONATION_LINK}>Doneer</Button>
      </Box>
      <Typography variant="h1" color="primary" gutterBottom sx={{mt: '35px'}}>
        Team
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={4} sx={{position: 'relative'}}>
          <TeamCard
            name="Floris Hoogenboom"
            title="Voorzitter (Expertise: AI)"
            imgSrc="/teamPhotos/floris.jpeg"
            email="floris@openverkiezingen.nl"
          >
            Ik ben ervan overtuigd dat AI niet alleen risico&apos;s, maar vooral ook kansen biedt!
            Met OpenVerkiezingen.nl proberen we AI op zo&apos;n manier in te zetten dat het onze
            democratie versterkt.
          </TeamCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TeamCard
            name="Charlotte van Kleef"
            title="Secretaris (Expertise Politicologie)"
            imgSrc="/teamPhotos/charlotte.jpeg"
            email="charlotte@openverkiezingen.nl"
          >
            Onze democratie is een belangrijk goed. Ik zet mij er graag voor in deze opener en
            transparanter te maken.
            Nieuwe technologische ontwikkelingen kunnen hieraan bijdragen.
            Hoe mooi dat we een platform hebben waarop dit daadwerkelijk gebeurt!
          </TeamCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TeamCard
            name="Joost de Bruijn"
            title="Penningmeester"
            imgSrc="/teamPhotos/joost.jpg"
            email="joost@openverkiezingen.nl"
          >
            Ik word enthousiast van zowel technologie als maatschappelijk relevante kwesties,
            vandaar dat ik me graag inzet om vanuit het bestuur van Stichting Open Politiek te
            werken aan een transparantere en meer toegankelijke democratie zonder misinformatie.
          </TeamCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{position: 'relative'}}>
          <TeamCard
            name="Nefeli Vonno"
            title="Redactie (Expertise Politicologie)"
            imgSrc="/teamPhotos/nefeli.jpeg"
          >
            Ik vind het mooi dat ik met  OpenVerkiezingen.nl mijn steentje kan bijdragen aan een
            transparantere democratie! Ik geloof dat OpenVerkiezingen een goede aanvulling is
            op de al bestaande stemwijzers en bijdraagt aan het toegankelijker maken van
            verkiezingen en politiek.
          </TeamCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TeamCard
            name="Julius Huitema"
            title="Design"
            imgSrc="/teamPhotos/julius.jpeg"
          >
            Tijdens mijn studie heb ik al een concept ontworpen dat een andere blik gaf op
            stemwijzers. Toen Floris mij vroeg of ik wou bijdragen aan OpenVerkiezingen.nl vanuit
            mijn design kennis kwam alles opeens samen.
          </TeamCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TeamCard
            name="Bram Kok"
            title="Product (Expertise UI/UX)"
            imgSrc="/teamPhotos/bram.jpeg"
          >
            Het leuke aan OpenVerkiezingen is dat het de positieve maatschappelijke impact van AI
            laat zien. Vandaar dat ik me bij Floris en het team heb gevoegd om te helpen met de
            ontwikkeling van een gebruiksvriendelijke tool.
          </TeamCard>
        </Grid>
      </Grid>
    </>
  );
}
