import Typography from '@mui/material/Typography';
import * as React from 'react';
import {TypeAnimation} from 'react-type-animation';
import Box from '@mui/material/Box';


export default function Header({sx}) {
  return (
    <Box
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      sx={sx}>
      <img src="logo_white.svg" style={{maxWidth: '900px'}} />
      <Typography
        variant="h4"
        component="h4"
        color="text.white"
        sx={{fontSize: {
          sm: 30,
        }}}
        gutterBottom>
        <TypeAnimation
          sequence={[
            'Verkiezingsprogramma\'s voor iedereen',
            1000,
            'Volledig transparant door gebruik van AI',
            1000,
            'Zelf bepalen wat jij belangrijk vindt',
            1000,
            'Kies jouw partijen hieronder en voer een stelling in om te zien wat zij vinden',
          ]}
          element={'div'}
          cursor={true}
          repeat={0}
        />
      </Typography>
    </Box>
  );
}
