import * as React from 'react';
import {useFeatureIsOn} from '@growthbook/growthbook-react';


export function FeatureFlag({name, children, invert = false}) {
  const featureIsOn = useFeatureIsOn(name);
  if (!invert) {
    return <>{featureIsOn && children}</>;
  } else {
    return <>{!featureIsOn && children}</>;
  }
}
