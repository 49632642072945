import * as React from 'react';
import Box from '@mui/material/Box';
import MenuBar from '../components/MenuBar';
import {Outlet} from 'react-router-dom';
import {useTheme} from '@mui/material';
import Footer from '../components/Footer';


export default function BaseView() {
  const theme = useTheme();


  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Box>
        <MenuBar
          sx={{
            background: {
              xs: theme.palette.primary.main,
              sm: theme.palette.primary.main,
            },
            position: 'fixed',
            display: {xs: 'block', sm: 'block'},
          }}
          logoOnFullScreen
        ></MenuBar>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
          p: '15px',
          pt: {
            xs: '65px',
            sm: '80px',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px',
          }}>
          <Outlet />
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
