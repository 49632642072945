import * as React from 'react';
import Box from '@mui/material/Box';
import * as amplitude from '@amplitude/analytics-browser';
import Button from '@mui/material/Button';
import ElectionSelector from './ElectionSelector';
import {FeatureFlag} from './FeatureFlag';

export default function SummaryActions({
  sx,
  data,
  party,
  election,
  setElection,
  onSimplify,
}) {
  return <Box
    sx={{
      ...sx,
      display: 'flex',
      justifyContent: 'space-between',
    }}>
    <ElectionSelector
      availableElections={party?.participates_in}
      election={election}
      setElection={setElection}></ElectionSelector>
    <FeatureFlag
      name="begrijpelijke-taal">
      <Button
        size="small"
        variant='outlined'
        disabled={!data?.summary}
        onClick={() => {
          amplitude.track('Simple Language button clicked');
          onSimplify();
        }}
      >Begrijpelijke taal</Button>
    </FeatureFlag>
  </Box>;
}
