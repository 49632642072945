import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      marginTop: '20px',
    },
    subtitle1: {
      fontSize: '0.8rem',
    },
    body1: {
      marginBottom: '10px',
      fontSize: '0.9rem',
      lineHeight: '1.5rem',
    },
  },
  palette: {
    background: {
      default: '#ffffff',
      grey: '#F5F5F5',
    },
    warning: {
      main: '#ff6600',
    },
    primary: {
      dark: '#002529',
      main: '#006D77',
      mainOpaque: 'rgba(0,109,119,0.8)',
      light: '#D3EAEB',
    },
    secondary: {
      main: '#ff6600',
      contrastText: '#FFF7F1',
    },
    text: {
      dark: '#000000',
      primary: '#666666',
      white: '#FFFFFF',
    },
  },
});

export default theme;
