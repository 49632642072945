import {useState, useEffect, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';
import {AuthContext} from './AuthContext';


export const useFetchGet = (defaultUrl, initialData, requiresAuth = false) => {
  const auth = useContext(AuthContext);
  const [url, setUrl] = useState(defaultUrl);
  const [data, setData] = useState(initialData);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const controller = new AbortController();
    const inner = async () => {
      let client;
      if (requiresAuth) {
        client = getAuthenticatedFetch(auth.token);
      } else {
        client = fetch;
      }

      setIsPending(true);
      const res = await client(url, {signal: controller.signal});
      if (res.ok) {
        const data = await res.json();
        setIsPending(false);
        setData(data);
        setError(null);
      } else {
        setIsPending(false);
        setData(null);
        setError({
          status: res.status,
          message: res.statusText,
        });
      }
    };

    if (url) {
      inner();
    }

    return () => {
      controller.abort();
    };
  }, [url]);

  const clear = () => {
    setData(initialData);
    setError(null);
  };

  return {
    data,
    setData,
    isPending,
    error,
    setUrl,
    clear,
  };
};

export const useFetchGetRef = (defaultUrl, initialData, requiresAuth = false) => {
  const auth = useContext(AuthContext);
  const [refUrl, setRefUrl] = useState(defaultUrl);
  const [refData, setRefData] = useState(initialData);
  const [isPendingRef, setIsPendingRef] = useState(false);
  const [refError, setRefError] = useState(null);


  useEffect(() => {
    const controller = new AbortController();
    const inner = async () => {
      let client;
      if (requiresAuth) {
        client = getAuthenticatedFetch(auth.token);
      } else {
        client = fetch;
      }

      setIsPendingRef(true);
      const res = await client(refUrl, {signal: controller.signal});
      if (res.ok) {
        const refData = await res.json();
        setRefData(refData);
        setIsPendingRef(false);
      } else {
        setRefData(null);
        setIsPendingRef(false);
        setRefError({
          status: res.status,
          message: res.statusText,
        });
      }
    };

    if (refUrl) {
      inner();
    }

    return () => {
      controller.abort();
    };
  }, [refUrl]);

  const clearRef = () => {
    setRefData(initialData);
  };

  return {
    refData,
    setRefData,
    isPendingRef,
    setRefUrl,
    clearRef,
    refError,
  };
};


export const useFetchPostOrPut = (initialData, requiresAuth = false, method = 'POST') => {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(initialData);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [numberOfRunningReq, setNumberOfRunningReq] = useState(0);


  useEffect(() => {
    setIsPending(numberOfRunningReq > 0);
  }, [numberOfRunningReq]);


  const send = async (url, body) => {
    let client;
    if (requiresAuth) {
      client = getAuthenticatedFetch(auth.token);
    } else {
      client = fetch;
    }

    setNumberOfRunningReq((previous) => previous + 1);
    const res = await client(
      url,
      {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      },
    );
    if (res.ok) {
      const data = await res.json();
      setNumberOfRunningReq((previous) => previous - 1);
      setData(data);
      setError(null);

      return data;
    } else {
      setNumberOfRunningReq((previous) => previous - 1);
      setData(null);
      setError({
        status: res.status,
        message: res.statusText,
      });

      return null;
    }
  };

  const clear = () => {
    setData(initialData);
    setError(null);
  };

  return {
    send,
    data,
    setData,
    isPending,
    error,
    clear,
  };
};


export const useDelayedState = (initialValue, delay = 500) => {
  const [directState, setDirectState] = useState(initialValue);
  const [delayedState, setDelayedState] = useState(initialValue);

  useEffect(() => {
    const delayQuery = setTimeout(() => {
      setDelayedState(directState);
    }, delay);

    return () => clearTimeout(delayQuery);
  }, [directState]);

  const setDelayedStateDirectly = (val) => {
    setDelayedState(val);
    setDirectState(val);
  };

  return [delayedState, setDirectState, directState, setDelayedStateDirectly];
};


export function useSearchParamsState(
  searchParamName,
  defaultValue,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState) => {
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce(
        (o, [key, value]) => ({...o, [key]: value}),
        {},
      ),
      {[searchParamName]: newState},
    );
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
}
