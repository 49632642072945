import * as React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import Embedded from './Embedded';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (process.env.REACT_APP_TYPE === 'EMBEDDED') {
  root.render(
    <Embedded />,
  );
} else {
  root.render(
    <App/>,
  );
}
