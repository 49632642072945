import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import theme from './theme';
import EmbeddedView from './embeddedViews/EmbeddedView';


const router = createBrowserRouter([
  {
    path: '/',
    element: <EmbeddedView></EmbeddedView>,
  },
]);

export default function Embedded() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
