import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as React from 'react';

export default function Footer({sx}) {
  return <Box
    sx={{
      backgroundColor: (t) => t.palette.background.grey,
      p: 3,
      ...sx,
    }}>
    <Typography
      sx={{
        color: 'text.secondary',
        opacity: 0.3,
        fontWeight: 'bold',
        textAlign: 'center',
      }}

    >
      OpenVerkiezingen.nl is een initiatief van Stichting Open Politiek in samenwerking met:
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '25px',
      }}>
      <Link
        href="https://weaviate.io"
        target="_blank"
      >
        <img
          src="/partnerLogos/weaviate.svg"
          style={{
            height: '50px',
          }}/>
      </Link>
      <Link
        href="https://typetone.ai/"
        target="_blank"
      >
        <img
          src="/partnerLogos/typetone.webp"
          style={{
            height: '35px',
          }}/>
      </Link>
      <Link
        href="https://dr-julius.nl/"
        target="_blank"
      >
        <img
          src="/partnerLogos/drjulius.png"
          style={{
            height: '25px',
          }}/>
      </Link>
      <Link
        href="https://bnr.nl/openverkiezingen"
        target="_blank"
      >
        <img
          src="/partnerLogos/bnr.png"
          style={{
            height: '50px',
          }}/>
      </Link>
      <Link
        href="https://berenschot.nl"
        target="_blank"
      >
        <img
          src="/partnerLogos/berenschot.png"
          style={{
            height: '20px',
            marginTop: '-5px',
          }}/>
      </Link>
      <Link
        href="https://wearetransformers.nl"
        target="_blank"
      >
        <img
          src="/partnerLogos/transformers.svg"
          style={{
            height: '35px',
            marginTop: '5px',
          }}/>
      </Link>

    </Box>
  </Box>;
}
