import {createContext} from 'react';

export const AuthContext = createContext({
  user: null,
  token: null,
  authErrors: null,
  authPending: false,
  login: () => {},
  logout: () => {},
});
