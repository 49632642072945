import * as React from 'react';
import {ExternallyControlledDialog} from './dialogs';
import {useDelayedState} from '../hooks';
import {useEffect} from 'react';
import {Alert, Grid} from '@mui/material';
import * as amplitude from '@amplitude/analytics-browser';
import Suggestions from './Suggestions';
import TextField from '@mui/material/TextField';

export default function SuggestStatementDialog({
  open,
  onClose,
  commitStatement,
  sx,
}) {
  const [
    delayedQuery,
    setQuery,
    query,
    setDelayedQuery] = useDelayedState('', 1000);

  useEffect(() => {
    if (!open) {
      setDelayedQuery('');
    }
  }, [open]);
  return <>
    <ExternallyControlledDialog
      open={open}
      onClose={onClose}
      title={`Stellinghulp`}>

      <Alert severity="info" color="primary">
        Met de stellinghulp helpen we jou een sterke stelling te formuleren op basis van een
        onderwerp. Momenteel is de stellinghulp is nog in B&egrave;ta.
      </Alert>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            variant="filled"
            color="primary"
            label="Voer een thema in..."
            inputProps={{maxLength: 50}}></TextField>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          {query && <Suggestions
            query={delayedQuery}
            onSelect={(v) => {
              amplitude.track('Statementhelper used');
              commitStatement(v);
              onClose();
            }}></Suggestions>}
        </Grid>
      </Grid>
    </ExternallyControlledDialog>
  </>;
}
