import * as React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';


export default function ElectionSelector({availableElections, election, setElection}) {
  return <ToggleButtonGroup
    color="primary"
    value={election}
    exclusive
    size="small"
    onChange={(e, id) => setElection(id)}
  >
    {availableElections?.map((e) => (
      <ToggleButton
        key={e.election_identifier}
        value={e.election_identifier}
        sx={{lineHeight: '1'}}>{e.election_identifier}</ToggleButton>
    ))}
  </ToggleButtonGroup>;
}
