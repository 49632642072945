import * as React from 'react';
import {ExternallyControlledDialog} from './dialogs';
import {useFetchPostOrPut} from '../hooks';
import {SUMMARIZER_URI} from '../globals';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Alert} from '@mui/material';
import InformedPendingHandler from './InformedPendingHandler';

export default function SimplifiedSummaryDialog({
  open,
  onClose,
  statementResult,
  setStatementResult,
  sx,
}) {
  const {send, data, clear, isPending} = useFetchPostOrPut(null);

  useEffect(() => {
    if (open && statementResult && !statementResult?.simplification) {
      clear();
      send(
        `${SUMMARIZER_URI}/simplify`,
        statementResult,
      );
    }
  }, [statementResult, open]);

  useEffect(() => {
    if (data) {
      setStatementResult((prev) => {
        return {
          ...prev,
          simplification: data?.simplification,
        };
      });
    }
  }, [data]);
  return <>
    <ExternallyControlledDialog
      open={open}
      onClose={onClose}
      title={`In simpele taal wat ${statementResult?.party?.party_identifier} zegt`}>
      <Alert severity="info" color="secondary">
        Hieronder vind je wat deze partij zegt in simpele taal
        over <b>&quot;{statementResult?.statement?.statement}&quot;</b>.
        Dit doen we door het antwoord opnieuw op te schrijven zonder dat we moeilijke woorden
        gebruiken.
      </Alert>
      <Divider sx={{mt: '10px', mb: '10px'}}></Divider>
      <InformedPendingHandler
        pending={isPending}
        party={statementResult?.party}
        paragraphs={statementResult?.paragraphs}
      ></InformedPendingHandler>
      <Typography variant="body1">
        {statementResult?.simplification}
      </Typography>
    </ExternallyControlledDialog>
  </>;
}
