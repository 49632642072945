import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useEffect, useState} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {useFetchGetRef} from '../hooks';
import {BASE_URI, SUMMARIZER_URI} from '../globals';
import CircularProgress from '@mui/material/CircularProgress';
import ControlledAccordion from './ControlledAccordion';
import SimplifiedSummaryDialog from './SimplifiedSummaryDialog';
import SummaryActions from './SummaryActions';
import Link from '@mui/material/Link';
import {Alert, AlertTitle, useTheme} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


export default function ReferenceSummary({
  data,
  setData,
  simplificationDialogOpen,
  setSimplificationDialogOpen,
  summary,
  generating,
  party,
  election,
  setElection,
  handleExpansion,
  expanded,
  setExpanded,
}) {
  const {refData, setRefUrl, isPendingRef, clearRef} = useFetchGetRef(null, null);
  const [references, setReferences] = useState(null);
  const theme = useTheme();
  let expansionIDs = {};
  let chapterReferences = [];

  useEffect(() => {
    if (summary) {
      setReferences(null);
      clearRef();

      let url = `${BASE_URI}/party/${party.party_identifier}/statementResult/${data.uuid}/reference`;// eslint-disable-line
      setRefUrl(url);
    }
  }, [data, party, summary]);

  useEffect(() => {
    if (data?.summary && !isPendingRef) {
      const createReferences = async (body) => {
        if (!(refData===references)) {
          await fetch(
            `${SUMMARIZER_URI}/references`,
            {
              method: 'post',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(body),
            },
          ).then(function(response) {
            return response.json();
          }).then(function(data) {
            setReferences(data);
          });
        }
      };

      if ((!refData && !references) || refData.length === 0) {
        createReferences(data);
      } else {
        setReferences(refData);
      }
    }
  }, [refData, data]);

  function handleExpansionReference(referenceID) {
    setExpanded(expansionIDs[referenceID]);
    amplitude.track(
      'Reference clicked',
      {refID: referenceID},
    );
  }

  function adaptSummary({summary}) {
    const newReferences = [];
    const summarySentences = summary.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|').map(
      (x) => [x, []],
    );
    for (let i=0; i < references.length; i++) {
      const zin = references[i]['zin'];
      for (let j=0; j < summarySentences.length; j++) {
        if (summarySentences[j][0] === zin) {
          for (let k=0; k < references[i]['references'].length; k++) {
            summarySentences[j][1].push(references[i]['references'][k]);
            newReferences.push([newReferences.length + 1, references[i]['references'][k]]);
          }
        }
      }
    }
    chapterReferences = newReferences;
    let counter = 0;
    return summarySentences?.map((s) => {
      return (
        <span key={s[0]}>
          {s[0]}
          <sup>
            {s[1].map((_) => {
              counter += 1;
              const id = counter;
              const dest = '#' + party.party_identifier + '_' + counter;
              return (
                <Link
                  href={dest}
                  key={id}
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    mr: '2px',
                  }}
                  onClick={() => handleExpansionReference(id)}>
                  {counter}
                </Link> // eslint-disable-line
              );
            })}
          </sup>
          {' '}
        </span>
      );
    });
  }

  function adaptParagraphs(paragraphs, references) {
    const expIDs = {};
    const newParagraphs = _.cloneDeep(paragraphs);

    for (let i = 0; i < newParagraphs.length; i++) {
      const p = newParagraphs[i];
      for (let j = references.length - 1; j >= 0; j--) {
        if (p.formatted_text.includes(references[j][1])) {
          const replacementReference = `<span style='font-weight: bold; color: ${theme.palette.primary.main}' id='${p.party.party_identifier}_${references[j][0]}'>${references[j][1]} [${references[j][0]}]</span>` // eslint-disable-line
          p.formatted_text = p.formatted_text.replace(references[j][1], replacementReference);
          expIDs[references[j][0]] = p.uuid;
        }
      }
    }
    expansionIDs = expIDs;
    return newParagraphs;
  }

  return (<>
    <SimplifiedSummaryDialog
      statementResult={data}
      setStatementResult={setData}
      open={simplificationDialogOpen}
      onClose={() => setSimplificationDialogOpen(false)}>
    </SimplifiedSummaryDialog>

    <Typography
      color="text.primary"
      variant="p"
      gutterBottom>
      {!references && <>
        {summary}
        {generating && <CircularProgress
          color="secondary"
          fontSize="6px"
          size="12px"
          sx={{marginLeft: '10px'}}/>}
        <br></br>
        <br></br>
        <span style={{fontStyle: 'italic'}}>Referenties aan het genereren</span> <CircularProgress
          color="secondary"
          fontSize="6px"
          size="12px"
          sx={{marginLeft: '10px'}}/>
      </>}
      {references && adaptSummary({summary})}

    </Typography>
    {references && <Alert
      color="info"
      icon={<WarningAmberIcon />}
      sx={{mt: 2, mb: 2}}>
      <AlertTitle>Referenties zijn in Beta</AlertTitle>
      Wij testen of referenties toegevoegde waarde hebben.
      Klik op de voetnoten om naar het originele programma te gaan.
    </Alert>}
    <SummaryActions
      data={data}
      sx={{
        mt: 1,
        mb: 1,
      }}
      onSimplify={() => setSimplificationDialogOpen(true)}
      party={party}
      election={election || data?.election?.electionIdentifier}
      setElection={setElection}/>
    <ControlledAccordion
      paragraphs={adaptParagraphs(data?.paragraphs, chapterReferences)}
      expanded={expanded}
      handleExpansion={handleExpansion}></ControlledAccordion>
  </>);
}


