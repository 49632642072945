import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import Header from '../components/Header';
import StatementEntry from '../components/StatementEntry';
import PartyButtons from '../components/PartyButtons';
import {useFetchGet, useSearchParamsState} from '../hooks';
import Demos from '../components/Demos';
import Box from '@mui/material/Box';
import {BASE_URI} from '../globals';
import AISummary from '../components/AISummary';
import {Grid, useTheme} from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import Suggestions from '../components/Suggestions';
import {FeatureFlag} from '../components/FeatureFlag';


function BottomSuggestions({
  query,
  onSelect,
}) {
  return <Box
    sx={{
      position: 'relative',
    }}>
    <Typography
      color="text.primary"
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <>
        <KeyboardDoubleArrowDownIcon
          sx={{mr: 1, verticalAlign: 'middle', color: 'secondary.main'}}/>
        <b>Verdiepende stellingen om te verkennen</b>
      </>
    </Typography>
    <Suggestions
      sx={{mt: '15px', mb: '15px'}}
      query={query}
      onSelect={onSelect}></Suggestions>
  </Box>;
}


export default function SummarizationView() {
  const resultRef = useRef(null);
  const firstRender = useRef(true);
  const [statement, setStatement] = useSearchParamsState('query', '');
  const [bnr] = useSearchParamsState('bnr', false);
  const [widgetType] = useSearchParamsState('widgetType', null);
  const [selectedParties, setSelectedParties] = useState([]);
  const [
    simplifiedSelectedParties,
    setSimplifiedSelectedParties,
  ] = useSearchParamsState('parties', '');
  const {data: parties} = useFetchGet(`${BASE_URI}/party/`, []);
  const theme = useTheme();

  useEffect(() => {
    amplitude.track(
      'Summarization view opened',
      {
        bnrForwarded: !!bnr,
        widgetType: widgetType,
        hasStatement: !!statement,
        hasParties: !!simplifiedSelectedParties,
      });
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      // Don't update this on the first render to allow parties to be set from the URL
      firstRender.current = false;
      return;
    }
    // When we change the selected parties, update the URL
    setSimplifiedSelectedParties(selectedParties.map((p) => p.party_identifier).toString(','));
  }, [selectedParties]);

  useEffect(() => {
    // When we load the parties for the first time, select those from the url

    if (parties?.length == 0) {
      // If no parties have been loaded yet, skip
      return;
    }

    if (simplifiedSelectedParties) {
      const partiesToSelect = simplifiedSelectedParties.split(',');

      setSelectedParties(
        parties.filter((p) => _.includes(partiesToSelect, p.party_identifier)),
      );

      if (statement) {
        resultRef.current.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [parties]);

  useEffect(() => {
    if (statement) {
      amplitude.track('Statement entered');
      resultRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [statement]);

  const handleClickOnParty = (party) => {
    if (_.includes(selectedParties.map((p) => p.party_identifier), party.party_identifier)) {
      // If the party is already in the list of current parties, deselect it
      setSelectedParties(
        (prev) => _.remove(prev, (p) => p.party_identifier != party.party_identifier),
      );
      amplitude.track('Party removed');
    } else {
      // Otherwise add it
      setSelectedParties((prev) => _.union(prev, [party]));
      amplitude.track('Party selected');
    }
  };

  const handleDemoChanged = (demo) => {
    setStatement(demo.statement.statement);
    amplitude.track('Demo clicked');
    resultRef.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Box
          sx={{
            backgroundImage: (t) => {
              return `linear-gradient(180deg, rgba(2,0,36,0) 0%, ` +
                `${t.palette.primary.mainOpaque} 75%), ` +
                `url(/header_background.svg)`;
            },
          }}>
          <MenuBar
            sx={{
              backgroundColor: {
                xs: theme.palette.primary.main,
                sm: theme.palette.primary.mainOpaque,
              },
              position: {xs: 'fixed', sm: 'relative'},
              display: {xs: 'block', sm: 'block'},
            }}
          ></MenuBar>
          <Box
            sx={{
              pb: '30px',
              pl: '15px',
              pr: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box sx={{width: '100%', flexShrink: 0}}>
              <Header sx={{
                pt: '20px',
                pb: '20px',
                display: {xs: 'none', sm: 'block'},
              }}/>
            </Box>

            <Box sx={{
              maxWidth: '1500px',
              mt: {xs: '70px', sm: '15px'},
            }}>
              <Typography
                color="text.white"
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mb: '15px',
                  mt: '15px',
                }}
              >
                <>
                  <KeyboardDoubleArrowDownIcon
                    sx={{mr: 1, verticalAlign: 'middle', color: 'secondary.main'}}/>
                  <b>Selecteer maximaal 3 partijen die jij met elkaar wil vergelijken</b>
                </>
              </Typography>
              <PartyButtons
                parties={parties}
                onClick={handleClickOnParty}
                currentParties={selectedParties}
                highlighted={selectedParties.length == 0}/>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: (t) => t.palette.primary.light,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundColor: '#FFFFFF',
              opacity: 0.75,
              zIndex: 10,
            }}
            hidden={selectedParties?.length > 0}></Box>
          <Box sx={{pb: '15px', pt: '15px', pl: '15px', pr: '15px'}}>
            <Typography
              color="text.primary"
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <>
                <KeyboardDoubleArrowDownIcon
                  sx={{mr: 1, verticalAlign: 'middle', color: 'secondary.main'}}/>
                <b>Kies een van onderstaande stellingen of voer zelf een stelling in</b>
              </>
            </Typography>
            <Demos
              onSelect={handleDemoChanged}
              sx={{mt: '15px', maxWidth: '900px'}}/>
            <StatementEntry
              sx={{mt: '15px'}}
              statement={statement}
              onStatementChanged={(val) => {
                setStatement(val);
              }}/>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexGrow: 1,
            mt: '15px',
            flexDirection: 'column',
          }}
          ref={resultRef}
        >
          {!(selectedParties?.length > 0) && (
            <Typography
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.3,
                fontWeight: 'bold',
                lineHeight: '45px',
                fontSize: 35,
                textAlign: 'center',
              }}
              component="div">
              Selecteer partijen om met elkaar te vergelijken.
            </Typography>
          )}
          {statement && (selectedParties?.length > 0) && <>
            <Grid
              container
              spacing={2}
              columns={12}
              sx={{flexShrink: 0}}
              justifyContent="center"
              alignItems="flex-start">
              {statement && selectedParties.map((party) =>
                <Grid item xs={12} md={4} key={party.party_identifier}>
                  <AISummary
                    party={party}
                    query={statement}
                    onClose={() => handleClickOnParty(party)}></AISummary>
                </Grid>,
              )}
            </Grid>
            <FeatureFlag
              name="bottom-suggestion">
              <Box
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                display="flex"
                sx={{mt: '30px'}}>
                <Box
                  sx={{maxWidth: '900px'}}>
                  <BottomSuggestions query={statement} onSelect={setStatement}/>
                </Box>

              </Box>
            </FeatureFlag>
          </>}
        </Box>

        <Footer sx={{mt: '15px'}}/>
      </Box>
    </>
  );
}
