import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


export default function HowView() {
  return (
    <>
      <Typography variant="h1" color="primary" gutterBottom>
        Hoe werkt OpenVerkiezingen?
      </Typography>
      <Typography variant="h2" color="secondary" gutterBottom>
        Standaardiseren van de verkiezingsprogramma&apos;s in een formaat
      </Typography>
      <Typography variant="body1" component="p">
        Het algoritme van OpenVerkiezingen begint bij de originele partijprogramma’s.
        De meeste politieke partijen publiceren deze in PDF-vorm. Om de partijprogramma’s
        beschikbaar te maken is de eerste stap om de partijprogramma’s uit de vormgeving van
        de partijen te halen en in een gestandaardiseerd formaat om te zetten.
        Zie voor de exacte methodologie hiervoor ons beleid.
      </Typography>
      <Typography variant="body1" component="p">
        Door het omzetten van de partijprogramma’s in een standaardformaat wordt het mogelijk deze
        allemaal in dezelfde structuur in OpenVerkiezingen te laden. We doen dit door het programma
        op te knippen in fragmenten van “Kleinere Tussenkop” tot “Kleinere Tussenkop” (of grotere
        kop als het het einde van een paragraaf of hoofdstuk betreft) op te knippen.
        Hierdoor wordt het partijprogramma een verzameling van allemaal kleine stukjes tekst.
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '25px',
        pl: {
          xs: '0px',
          sm: '30px',
        },
        pr: {
          xs: '0px',
          sm: '30px',
        },
      }}>
        <img src="/contentImages/how_program_to_pieces.svg" style={{width: '100%'}}/>
      </Box>


      <Typography variant="h2" color="secondary" gutterBottom>
        Semantisch doorzoekbaar maken van de verkiezingsprogramma&apos;s
      </Typography>
      <Typography variant="body1" component="p">
        Met de partijprogramma’s in fragmenten opgesplitst in kleine fragmenten is het nu
        belangrijk om deze doorzoekbaar te maken. Het probleem hierbij is dat simpelweg zoeken op
        woorden alleen onvoldoende werkt. Neem bijvoorbeeld de stelling
        “Nederland moet investeren in AI”. Een fragment dat iets noemt over investeringen in
        “Kunstmatige Intelligentie” (Een ander woord voor AI) is natuurlijk heel relevant.
        Maar ook een fragment dat bijvoorbeeld spreekt over “De kansen van ChatGPT voor het MKB”
        zal in de zoekresultaten moeten terugkomen, maar dit gebeurt niet als je alleen op woorden
        zoekt. Hierom maken we gebruik van zogeheten semantic search. Een volledige uitleg van de
        technologie achter semantic search voert te ver voor dit artikel, maar in hoofdlijnen
        is het idee het beste te vergelijken met een kaart. We geven alle fragmenten op zo’n manier
        een plek op deze kaart dat artikelen die over hetzelfde onderwerp gaan dicht bij elkaar
        liggen en artikelen die andere, niet gerelateerde onderwerpen betreffen ver uit elkaar
        liggen. Om dit te kunnen doen maken we gebruik van de innovatieve technologie van de
        Amsterdamse startup Weaviate.
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '25px',
        pl: {
          xs: '0px',
          sm: '30px',
        },
        pr: {
          xs: '0px',
          sm: '30px',
        },
      }}>
        <img src="/contentImages/how_pieces_to_vectors.svg" style={{width: '100%'}}/>
      </Box>

      <Typography variant="h2" color="secondary" gutterBottom>
        We vinden de juiste fragmenten bij de stelling van een gebruiker
      </Typography>
      <Typography variant="body1" component="p">
        Op het moment dat een gebruiker een stelling invoert op het platform gebruiken we de kaart
        waarop alle onderwerpen op semantische betekenis bij elkaar liggen om de relevante
        fragmenten uit het verkiezingsprogramma voor deze stelling erbij te zoeken. We doen dit
        eigenlijk door hetzelfde proces als hierboven beschreven twee keer toe te passen.
      </Typography>
      <Typography variant="body1" component="p">
        Stel de gebruiker geeft als stelling op “Ik vind dat Nederland meer moet investeren in
        Digitale technologie”. Als eerste gebruiken we dan de kaart die we hierboven gemaakt hebben
        om de positie van de gebruiker te bepalen door zijn stelling “op de kaart te plaatsen”.
        Vervolgens kijken we op de kaart wat daar dichtbij ligt.
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '25px',
        pl: {
          xs: '0px',
          sm: '30px',
        },
        pr: {
          xs: '0px',
          sm: '30px',
        },
      }}>
        <img src="/contentImages/how_search.svg" style={{width: '100%'}}/>
      </Box>
      <Typography variant="body1" component="p">
        In het geval van deze stelling zouden onderwerpen als “digitale veiligheid en weerbaarheid”
        maar ook “Investeringen in AI” als relevante fragmenten naar voren komen.
      </Typography>

      <Typography variant="h2" color="secondary" gutterBottom>
        Presentatie van het resultaat
      </Typography>
      <Typography variant="body1" component="p">
        Nu we de relevante fragmenten hebben van het verkiezingsprogramma geven we de gebruiker
        deze direct terug. Dit is ook wat we doen op openverkiezingen.nl. We laten zien welke
        fragmenten uit het verkiezingsprogramma het standpunt van de partij bevatten of informatie
        over aanverwante thema’s geven. Naast het teruggeven van deze fragmenten geeft
        OpenVerkiezingen ook een samenvatting. Hiervoor stelt OpenVerkiezingen de vraag aan een
        taalmodel (een soort ChatGPT) om op basis van de gevonden fragmenten het standpunt van de
        partij samen te vatten. Het algoritme vraagt dus nooit (!) direct aan een taalmodel om het
        antwoord te geven, maar geeft altijd de context mee waarin het antwoord te vinden is. Om te
        zorgen dat het model een zo laag mogelijke kans heeft om dingen te verzinnen beperken we de
        “creativiteit” van het model door bepaalde parameters.
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '25px',
        pl: {
          xs: '0px',
          sm: '30px',
        },
        pr: {
          xs: '0px',
          sm: '30px',
        },
      }}>
        <img src="/contentImages/how_search_to_result.svg" style={{width: '100%'}}/>
      </Box>
    </>
  );
}
